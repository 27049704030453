<template>

  <div class="card">
    <div class="card-title" @click="toggleVisibility">
      <div class="title">{{ translations.companyInformation.title }}</div>
      <div :class="{arrow: true, closed: !isVisible}">
        <img src="~@/assets/arrow.svg" />
      </div>
    </div>

    <transition name="slide">
      <div class="hiding" v-show="isVisible">
        <div class="company-search-wrapper">
          <CompanySearch @selected="companyChanged" :name="company.name"/>
        </div>

        <div class="field-wrapper" v-show="company.name">
          <Select
              @selected="setCompanyValue('type', $event)"
              :value="company.type"
              :title="translations.companyInformation.companyTypeTitle"
              :options="translations.companyInformation.companyTypes"
          />
        </div>

        <div class="field-wrapper" v-show="company.name">
          <Select
              @selected="setCompanyValue('vat', $event)"
              :value="company.vat"
              :title="translations.companyInformation.companyVatTitle"
              :options="translations.select.yesNo"
          />
        </div>

        <div class="field-wrapper" v-show="company.name">
          <Select
              @selected="setCompanyValue('employees', $event)"
              :value="company.employees"
              :title="translations.companyInformation.companyEmployeesTitle"
              :options="translations.select.yesNo"
          />
        </div>

        <div class="button-wrapper">
          <Button :text="translations.companyInformation.next" :disabled="!canProceed" @click="proceed"/>
        </div>

      </div>

    </transition>
  </div>

</template>

<script>
import {translations} from "@/utils";
import CompanySearch from "@/components/CompanySearch";
import Button from "@/components/Button";
import Select from "@/components/Select";

export default {
  name: 'CompanyInformation',
  components: {
    Button,
    CompanySearch,
    Select,
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    open(val) {
      this.isVisible = val;
    }
  },
  data() {
    return {
      translations: translations,
      isVisible: true,
      company: {
        name: "",
        number: "",
        type: "",
        vat: false,
        employees: false,
      }
    }
  },
  computed: {
    canProceed() {
      return this.company.name && this.company.type;
    }
  },
  methods: {
    companyChanged(company) {
      this.company = company;
      this.$emit('dataChanged', this.company);
    },
    setCompanyValue(field, value) {
      this.company[field] = value;
      this.$emit('dataChanged', this.company);
    },
    proceed() {
      if (this.canProceed) {
        this.$emit('next');
      }
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    }
  },
  mounted() {
    this.isVisible = this.open;
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .hiding {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .arrow {
    align-self: center;
    display: flex;

    img {
      height: calc(var(--rem) * 4);
      width: calc(var(--rem) * 8);
    }

    &.closed img {
      transform: rotate(180deg);
    }
  }

  .title {
    display: block;
    font-weight: 700;
  }

  .company-search-wrapper {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 28);
  }

  .field-wrapper {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 32);
  }

  .button-wrapper {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 32);
  }
}
</style>
