<template>
  <div :class="{button: true, disabled: disabled}">
    {{ text }}
    <img v-if="icon" :src="icon"/>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      required: false,
    },
  }
}
</script>

<style scoped lang="scss">
#price-calculator {
  .button {
    border-radius: 6px;
    color: #FCFCFC;
    background: #8061FF;
    font-weight: bold;
    padding: calc(var(--rem) * 14);
    cursor: pointer;
    display: flex;
    justify-content: center;

    &.disabled {
      color: #8B95B7;
      background: #EBEDF1;
      cursor: not-allowed;
    }

    img {
      display: inline-block;
      margin-left: calc(var(--rem) * 5);
      max-height: calc(var(--rem) * 20);
    }
  }
}
</style>
