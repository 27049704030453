import axios from "axios";
import config from "@/config";

import en from "@/locales/en";
import no from "@/locales/no";

const locales = {
    en,
    no,
}

function getTranslations() {
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    let locale = config.defaultLocale;

    if (browserLocales) {
        for (let browserLocale of browserLocales) {
            const trimmedLocale = browserLocale.trim().split(/-|_/)[0];
            if (locales[trimmedLocale]) {
                locale = trimmedLocale;
                break;
            }
        }
    }

    return locales[locale];
}

export const translations = getTranslations();

export function calculatePrice(data) {
    return axios.post(config.backendUrl + '/get-price', data);
}

export function contactManager(data) {
    return axios.post(config.backendUrl + '/contact-manager', data);
}
