export default {
    header: {
        main: "Fastpriskalkulator",
        sub: "Velg hva du vil ha gjort og estimer en fastpris" ,
    },
    companyInformation: {
        title: "Foretaksinformasjon",
        search: "Søk etter foretak eller skriv inn manuelt",
        next: "Neste",
        noResults: "Ingen foretak ble funnet",
        companyTypeTitle: "Organisasjonsform",
        companyTypes: [
            {value: "Limited", name: "Aksjeselskap"},
        ],
        companyVatTitle: "MVA-registrert",
        companyEmployeesTitle: "Ansatte",
    },
    whoDoesWhat: {
        title: "Hvem gjør hva",
        table: {
            topic: "Tjeneste",
            accountingCompany: "Regnskap Nord AS",
            vouchers: "Kontere bilag",
            salaries: "Lønnskjøring",
            vatStatement: "MVA-rapportering",
            yearlyAccounts: "Årsregnskap",
            taxReport: "Årlig skattemelding",
            shareholderReport: "Aksjonærregisteroppgave",
        }
    },
    additionalInformation: "Tilleggsinformasjon",
    voucherInfo: {
        title: "Bilag",
        numberOfVouchers: "Antall bilag (pr. måned)",
        complexAccounting: "Føres det avdeling, lager- eller prosjektregnskap?",
        cashSales: "Virksomheten har kontantsalg",
        postingRevisions: {
            subtitle: "Hvor ofte skal regnskapskontoret kontrollere bilagsbehandlingen du har gjort?",
            continuous: "Løpende",
            periodic: "Periodevis / Annenhver måned",
            yearly: "Årlig",
        }
    },
    salaryInfo: {
        title: "Lønnskjøring",
        paycheckNumber: "Antall lønnsslipper (per måned)",
        claimsNumber: "Antall utlegg/reiseregninger (per måned)",
    },
    shareholderInfo: {
        title: "Aksjonærer",
        numberOfShareholders: "Antall aksjonærer",
    },
    estimatedPrice: {
        title: "Estimert fastpris",
        contactManager: "Kontakt oss for samarbeid",
        perMonth: "Per måned",
        perYear: "Per år",
        exVat: "Alle priser er eks. mva"
    },
    contactManager: {
        title: "Kontakt leder",
        name: "Navnet ditt",
        phone: "Kontakt telefon",
        email: "Din epost",
        contactButton: "Send forespørsel",
        successSending: "Takk, forespørselen din er sendt. Vi vil kontakte deg så snart vi kan",
        welcomeMessage: "Skriv inn din e-postadresse eller telefonnummer nedenfor, så kontakter vi deg.",
        emailOrPhone: "E-post eller telefonnummer",
        contactMe: "Kontakt meg",
        orCall: "Eller ring Michael på:",
        phoneNumber: "922 15 328"
    },
    select: {
        yesNo: [
            {value: true, name: "Ja"},
            {value: false, name: "Nei"}
        ],
    },
    calculatePrice: "Kalkuler fastpris",
}
