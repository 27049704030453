<template>
  <div class="extended-row gray-bg">
    <div class="row no-gutters">
      <Loader v-if="isSending" bg-color="#EBEDF1"/>

      <div class="overlay-message" v-if="successSending">
        {{ translations.contactManager.successSending }}
      </div>

      <div class="row no-gutters welcome-text">
        {{ translations.contactManager.welcomeMessage }}
      </div>

      <div class="row">
        <div :class="{'field-wrapper': true, 'invalid': errors.contact}">
          <input
              class="input-field"
              v-model="customer.contact"
              :placeholder="translations.contactManager.emailOrPhone"
              @change="checkField('contact')"
          />
        </div>

        <Button
            :text="translations.contactManager.contactMe"
            @click="contactManager"/>
      </div>

    </div>
    <div class="row no-gutters">
      <img class="michael-photo" src="~@/assets/michael-photo.png"/>
      <div style="margin-left: 20px">
        <div class="row no-gutters">
          {{ translations.contactManager.orCall }}
        </div>
        <div class="row" style="margin-bottom: 0;">
          <img class="phone-icon" src="~@/assets/phone.svg">
          <a :href="'tel:'+ translations.contactManager.phoneNumber.replace(/\s/g, '')" class="phone-number">{{ translations.contactManager.phoneNumber }}</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {translations} from "@/utils";
import Button from "@/components/Button";
import Loader from "@/components/Loader";

export default {
  name: 'ContactManagerInline',
  components: {Loader, Button },
  props: {
    isSending: {
      required: true,
    },
    successSending: {
      required: true,
    },
  },
  data() {
    return {
      translations: translations,
      customer: {
        contact: null,
      },
      errors: {}
    }
  },
  methods: {
    contactManager() {
      if (this.formIsValid()) {
        this.$emit('send-request', this.customer);
      } else {
        this.checkField('contact');
      }
    },
    isValid(fieldName) {
      switch (fieldName) {
        case 'contact':
          return this.customer.contact && this.customer.contact.length;
      }
      return false;
    },
    checkField(fieldName) {
      this.errors[fieldName] = !this.isValid(fieldName);
    },
    formIsValid() {
      return this.isValid('contact', true);
    }
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .row {
    flex-basis: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--rem) * 21);
    margin-bottom: calc(var(--rem) * 21);
  }

  .extended-row {
    margin-left: calc(var(--rem) * -40);
    margin-right: calc(var(--rem) * -40);
    flex-grow: 1;
    padding: calc(var(--rem) * 20) calc(var(--rem) * 40);
    margin-bottom: calc(var(--rem) * -33);
  }

  .michael-photo {
    max-width: 40%;
  }

  .phone-icon {
    margin-right: 10px;
  }

  .welcome-text {
    text-align: left;
    line-height: calc(var(--rem) * 27);
    font-weight: 500;
  }

  .phone-number {
    color: #7A67F6;
    font-weight: 500;
    cursor: pointer;
  }

  .gray-bg {
    background: #EBEDF1;
  }

  .no-gutters {
    margin: 0 !important;
    padding: 0 !important;
  }

  .field-wrapper {
    height: calc(var(--rem) * 48);
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #CBD2E9;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    flex-basis: 50%;
    flex-grow: 1;
    margin-right: calc(var(--rem) * 10);
    background: white;

    &.invalid {
      border-color: darkred;
    }

    .input-field {
      flex-basis: 100%;
      width: 100%;
      text-align: left;
      margin: 0 calc(var(--rem) * 15);

      ::placeholder {
        color: #8B95B7;
      }

    }
  }

  .button-wrapper {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 24);
  }

  .overlay-message {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #EBEDF1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    font-size: 100%;
    line-height: 200%;
    color: #555;
    left: 0;
    top: 0;
    padding: calc(var(--rem) * 21);
    box-sizing: border-box;
  }

}
</style>
