<template>
  <div class="card">
    <div class="card-title" @click="toggleVisibility">
      <div class="title">{{ translations.whoDoesWhat.title }}</div>
      <div :class="{arrow: true, closed: !isVisible}">
        <img src="~@/assets/arrow.svg" />
      </div>
    </div>

    <transition name="slide">
      <div class="hiding" v-show="isVisible">
        <div class="table">
          <div class="table-header col-1">{{ translations.whoDoesWhat.table.topic }}</div>
          <div class="table-header col-2">{{ company.name }}</div>
          <div class="table-header col-3">{{ translations.whoDoesWhat.table.accountingCompany }}</div>

          <template v-for="choice in Object.getOwnPropertyNames(whoDoesWhat)" :key="choice">
            <div class="flex-break row-spacing"></div>
            <div class="table-body col-1">{{ translations.whoDoesWhat.table[choice] }}</div>
            <div class="table-body col-2">
              <input type="radio" class="custom-checkbox" :checked="whoDoesWhat[choice] === 0" @change="setChoice(choice, 0)">
            </div>
            <div class="table-body col-3">
              <input type="radio" class="custom-checkbox" :checked="whoDoesWhat[choice] === 1" @change="setChoice(choice, 1)">
            </div>
            <div class="flex-break row-spacing"></div>
          </template>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {translations} from "@/utils";

export default {
  name: 'WhoDoesWhat',
  components: {},
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    company: {
      required: true,
    },
    whoDoesWhat: {
      required: true,
    },
  },
  watch: {
    open(val) {
      this.isVisible = val;
    }
  },
  data() {
    return {
      translations: translations,
      isVisible: true,
    }
  },
  methods: {
    setChoice(field, value) {
      let whoDoesWhat = this.whoDoesWhat
      whoDoesWhat[field] = value;
      this.$emit('dataChanged', this.whoDoesWhat);
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    }
  },
  mounted() {
    this.isVisible = this.open;
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .hiding {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .arrow {
    align-self: center;
    display: flex;

    img {
      height: calc(var(--rem) * 4);
      width: calc(var(--rem) * 8);
    }

    &.closed img {
      transform: rotate(180deg);
    }
  }

  .title {
    display: block;
    font-weight: 700;
  }

  .table {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--rem) * 28);

    .col-1 {
      flex-basis: 43%;
      flex-grow: 1;
      white-space: break-spaces;
      word-break: break-word;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .col-2, .col-3 {
      flex-basis: 30%;
      flex-grow: 0;
      flex-shrink: 1;
      justify-content: center;
    }

    .col-2 {
      flex-basis: 27%;
    }

    .table-header {
      font-size: calc(var(--rem) * 14);
      color: #6A759D;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: calc(var(--rem) * 14);
      border-bottom: 1px solid #DEE0E8;
      margin-bottom: calc(var(--rem) * 7);
      white-space: nowrap;

      &.col-1, &.col-2, &.col-3 {
        align-self: flex-end;
      }

      &.col-2, &.col-3 {
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    .table-body {
      font-size: calc(var(--rem) * 14);

      &.col-1, &.col-2, &.col-3 {
        min-height: calc(var(--rem) * 46);
        display: flex;
        align-items: center;
      }
    }


    .row-spacing {
      margin-top: calc(var(--rem) * 1);
    }

    .custom-checkbox {
      display: inline;
      user-select: none;
      cursor: pointer;

      &::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #CBD2E9;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }

      &:checked::before {
        border-color: #8061FF;
        background-color: #8061FF;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'%3e%3cpath stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M1.71289 3.56619L3.90589 5.75919L8.69289 0.971191'/%3e%3c/svg%3e");
      }
    }
  }
}
</style>
