<template>
  <div>
    <div class="dropdown-select">
      <div class="title">
        {{ title }}
      </div>
      <div class="text-field" @click="toggleDropdown">
        {{ displayValue }}
      </div>
      <div class="arrow" @click="toggleDropdown">
        <img src="~@/assets/arrow.svg" />
      </div>

      <div class="options" v-if="showDropdown">
        <div class="option" v-for="(item, index) in options" :key="index" @click="selected(item.value)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    displayValue() {
      let displayValue = null;
      if (Array.isArray(this.options)) {
        this.options.forEach((item) => {
          if (item.value === this.value) {
            displayValue = item.name;
            return false;
          }
        })
      }
      return displayValue ?? this.value;
    }
  },
  mounted() {
    document.addEventListener("click", () => {
      if (this.showDropdown) this.hideDropdown();
    }, {capture: true});
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
    selected(val) {
      this.$emit('selected', val);
    }
  }
}
</script>

<style scoped lang="scss">
#price-calculator {
  .dropdown-select {
    height: calc(var(--rem) * 48);
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #CBD2E9;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    cursor: pointer;

    .title {
      position: absolute;
      top: calc(var(--rem) * -10);
      left: calc(var(--rem) * 20);
      font-size: calc(var(--rem) * 12);
      background: white;
      color: #6A759D;
      padding: 2px;
    }

    .arrow {
      align-self: center;
      padding: 0 20px;

      img {
        height: calc(var(--rem) * 4);
        width: calc(var(--rem) * 8);
        transform: rotate(180deg);
      }
    }

    .text-field {
      flex-basis: 100%;
      text-align: left;
      padding: calc(var(--rem) * 15);

      ::placeholder {
        color: #8B95B7;
      }
    }

    > .options {
      z-index: 101;
      position: absolute;
      top: calc(var(--rem) * 56);
      width: calc(100% - (var(--rem) * 16));
      max-height: calc(var(--rem) * 100);
      background: #FFFFFF;
      box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      padding: calc(var(--rem) * 8);

      .option {
        color: #201F22;
        text-align: left;
        padding: calc(var(--rem) * 8);
        cursor: pointer;

        &:hover {
          background: #F9F9F9;
        }
      }
    }
  }
}
</style>
