<template>
  <div class="company-search">
    <div class="field-icon">
      <img src="~@/assets/search-icon.svg" />
    </div>
    <input
        class="input-field"
        v-model="inputValue"
        :placeholder="translations.companyInformation.search"
        @input="inputChanged"
        @focusout="focusLost"/>
    <div class="search-results" v-if="searchResults">
      <div class="no-results" v-if="searchResults.length === 0">
        {{ translations.companyInformation.noResults }}
      </div>
      <div v-else class="result-item" v-for="item in searchResults" :key="item.name" @click="selected(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {translations} from "@/utils";

export default {
  name: 'CompanyInformation',
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      translations: translations,
      inputValue: this.name,
      lastInputAt: 0,
      searchResults: null,
    }
  },
  watch: {
    name(val) {
      this.inputValue = val;
    }
  },
  methods: {
    inputChanged() {
      this.lastInputAt = new Date().getTime();
      if (this.inputValue.length > 3) {
        setTimeout(this.search, 501);
      }
    },
    search() {
      if (this.lastInputAt < new Date().getTime() - 500) {
        axios.get("https://data.brreg.no/enhetsregisteret/api/enheter?navn=" + this.inputValue)
            .then((data) => {
              if (data["data"]["_embedded"]) {
                this.searchResults = data["data"]["_embedded"]["enheter"].slice(0, 5).map((company) => {
                  return {
                    name: company["navn"],
                    number: company["organisasjonsnummer"],
                    type: company["organisasjonsform"]["beskrivelse"],
                    vat: company["registrertIMvaregisteret"],
                    employees: company["antallAnsatte"] > 0,
                  }
                });
              } else {
                this.searchResults = [];
              }
            })
      }
    },
    focusLost() {
      setTimeout(() => {
        this.searchResults = null;
        this.inputValue = this.name;
      }, 200);
    },
    selected(item) {
      this.$emit('selected', item);
    }
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .company-search {
    height: calc(var(--rem) * 48);
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #CBD2E9;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
  }

  .field-icon {
    align-self: center;
    padding: 0 calc(var(--rem) * 20);;

    img {
      height: calc(var(--rem) * 18);
      width: calc(var(--rem) * 18);
    }
  }

  .input-field {
    flex-basis: 100%;
    width: 100%;
    text-align: left;

    ::placeholder {
      color: #8B95B7;
    }
  }

  .search-results {
    position: absolute;
    top: calc(var(--rem) * 56);
    width: calc(100% - (var(--rem) * 16));
    background: #FFFFFF;
    box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    z-index: 1;
    padding: calc(var(--rem) * 8);
  }

  .result-item {
    color: #201F22;
    text-align: left;
    padding: calc(var(--rem) * 8);
    cursor: pointer;

    &:hover {
      background: #F9F9F9;
    }
  }

  .no-results {
    color: #201F22;
    text-align: left;
    padding: calc(var(--rem) * 8);
  }
}
</style>
