<template>
  <div class="number-input">
    <input
        class="input-field"
        :value="value"
        @input="inputChanged"/>
    <div class="field-button" @click="changeValue(-1)">
      <img src="~@/assets/minus.svg"/>
    </div>
    <div class="field-button" @click="changeValue(1)">
      <img src="~@/assets/plus.svg"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      default: 0,
    }
  },
  methods: {
    inputChanged(evt) {
      this.$emit('valueChanged', evt.target.value * 1);
    },
    changeValue(diff) {
      this.$emit('valueChanged', Number(this.value) + diff);
    }
  },
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .number-input {
    height: calc(var(--rem) * 48);
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #CBD2E9;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-basis: 100%;
  }

  .field-button {
    align-self: center;
    padding: calc(var(--rem) * 13);;
    cursor: pointer;
    border-left: 1px solid #CBD2E9;

    img {
      height: calc(var(--rem) * 18);
      width: calc(var(--rem) * 18);
    }
  }

  .input-field {
    flex-basis: 100%;
    width: 100%;
    text-align: left;
    padding-left: calc(var(--rem) * 15);

    ::placeholder {
      color: #8B95B7;
    }
  }
}
</style>
