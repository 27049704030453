<template>
  <div class="card">
    <Loader v-if="estimatedPrice === null"/>

    <div class="title">{{ translations.estimatedPrice.title }}</div>

    <div class="row">
      <div class="label">
        {{ translations.estimatedPrice.perMonth }}
      </div>
      <div class="amount">
        {{ formatNumber(estimatedPrice?.perMonth) }}
      </div>
      <div class="currency">
        NOK
      </div>
    </div>

    <div class="row">
      <div class="label">
        {{ translations.estimatedPrice.perYear }}
      </div>
      <div class="amount">
        {{ formatNumber(estimatedPrice?.perYear) }}
      </div>
      <div class="currency">
        NOK
      </div>
    </div>

    <div class="comment">
      {{ translations.estimatedPrice.exVat }}
    </div>

    <div class="row no-gutters">
      <ContactManagerInline
          :is-sending="isSending"
          :success-sending="successSending"
          @send-request="contactManager"
      />
    </div>
  </div>
</template>

<script>
import {translations} from "@/utils";
import Loader from "@/components/Loader";
import ContactManagerInline from "@/components/ContactManagerInline";

export default {
  name: 'EstimatedPrice',
  components: { Loader, ContactManagerInline },
  props: {
    estimatedPrice: {
      required: true
    },
    isSending: {
      default: false,
    },
    successSending: {
      default: false,
    },
  },
  data() {
    return {
      translations: translations,
    }
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat('en-US').format(number);
    },
    contactManager(customer) {
      this.$emit('send-request', customer);
    }
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .card {
    overflow: hidden;
  }

  .title {
    display: block;
    font-weight: 700;
    margin-bottom: calc(var(--rem) * 8);
  }

  .row {
    flex-basis: 100%;
    align-items: center;
    display: flex;
    margin-top: calc(var(--rem) * 21);
    padding-bottom: calc(var(--rem) * 24);
    margin-bottom: calc(var(--rem) * 10);
    border-bottom: 1px solid #CBD2E9;

    &.no-gutters {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    .label {
      color: #1C1B1C;
      flex-basis: 100%;
      text-align: left;
    }

    .amount {
      color: black;
      font-size: calc(var(--rem) * 36);
    }

    .currency {
      align-self: flex-start;
      font-size: calc(var(--rem) * 12);
      margin-top: calc(var(--rem) * 5);
      margin-left: calc(var(--rem) * 8);
    }
  }

  .comment {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 24);
    color: #8B95B7;
    text-align: right;
    font-style: italic;
  }

  .button-wrapper {
    flex-basis: 100%;
  }
}
</style>
