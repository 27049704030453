<template>
  <div class="card">
    <div class="card-title" @click="toggleVisibility">
      <div class="title">{{ translations.voucherInfo.title }}</div>
      <div :class="{arrow: true, closed: !isVisible}">
        <img src="~@/assets/arrow.svg" />
      </div>
    </div>

    <transition name="slide">
      <div v-if="type === 1" class="hiding" v-show="isVisible">
        <div class="label">
          {{ translations.voucherInfo.numberOfVouchers }}
        </div>

        <NumberInput :value="voucherInfo.number" @valueChanged="setVoucherInfo('number', $event)"/>

        <div class="row">
          <input type="checkbox" class="custom-checkbox" :checked="voucherInfo.complexAccounting" @change="setVoucherInfo('complexAccounting', !voucherInfo.complexAccounting)"/>
          <div class="inline-label">
            {{ translations.voucherInfo.complexAccounting }}
          </div>
        </div>

        <div class="row">
          <input type="checkbox" class="custom-checkbox" :checked="voucherInfo.cashSales" @change="setVoucherInfo('cashSales', !voucherInfo.cashSales)"/>
          <div class="inline-label">
            {{ translations.voucherInfo.cashSales }}
          </div>
        </div>
      </div>

      <div v-else class="hiding" v-show="isVisible">
        <div class="label">
          {{ translations.voucherInfo.numberOfVouchers }}
        </div>

        <NumberInput :value="voucherInfo.number" @valueChanged="setVoucherInfo('number', $event)"/>

        <div class="row text">
          {{ translations.voucherInfo.postingRevisions.subtitle }}
        </div>

        <div class="row">
          <input type="radio" class="custom-radio" :checked="voucherInfo.postingRevisions === 'continuous'" @change="setVoucherInfo('postingRevisions', 'continuous')"/>
          <div class="inline-label">
            {{ translations.voucherInfo.postingRevisions.continuous }}
          </div>
        </div>

        <div class="row">
          <input type="radio" class="custom-radio" :checked="voucherInfo.postingRevisions === 'periodic'" @change="setVoucherInfo('postingRevisions', 'periodic')"/>
          <div class="inline-label">
            {{ translations.voucherInfo.postingRevisions.periodic }}
          </div>
        </div>

        <div class="row">
          <input type="radio" class="custom-radio" :checked="voucherInfo.postingRevisions === 'yearly'" @change="setVoucherInfo('postingRevisions', 'yearly')"/>
          <div class="inline-label">
            {{ translations.voucherInfo.postingRevisions.yearly }}
          </div>
        </div>

      </div>

    </transition>
  </div>
</template>

<script>
import {translations} from "@/utils";
import NumberInput from "@/components/NumberInput";

export default {
  name: 'VoucherInfo',
  components: { NumberInput },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    voucherInfo: {
      required: true
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    open(val) {
      this.isVisible = val;
    }
  },
  data() {
    return {
      translations: translations,
      isVisible: true,
    }
  },
  methods: {
    setVoucherInfo(field, value) {
      let voucherInfo = this.voucherInfo;
      voucherInfo[field] = value;
      this.$emit('dataChanged', voucherInfo);
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    }
  },
  mounted() {
    this.isVisible = this.open;
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .hiding {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .arrow {
    align-self: center;
    display: flex;

    img {
      height: calc(var(--rem) * 4);
      width: calc(var(--rem) * 8);
    }

    &.closed img {
      transform: rotate(180deg);
    }
  }

  .title {
    display: block;
    font-weight: 700;
  }

  .label {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 21);
    margin-bottom: calc(var(--rem) * 8);
    text-align: left;
  }

  .inline-label {
    text-align: left;
  }

  .custom-checkbox {
    display: inline;
    user-select: none;
    margin-left: 3px;
    margin-right: calc(var(--rem) * 15);
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #CBD2E9;
      border-radius: 3px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }

    &:checked::before {
      border-color: #8061FF;
      background-color: #8061FF;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'%3e%3cpath stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M1.71289 3.56619L3.90589 5.75919L8.69289 0.971191'/%3e%3c/svg%3e");
    }
  }

  .custom-radio {
    display: inline;
    user-select: none;
    margin-left: 3px;
    margin-right: calc(var(--rem) * 15);
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #CBD2E9;
      border-radius: 1em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }

    &:checked::before {
      border-color: #8061FF;
      background-color: white;
      background-image: url('~@/assets/dot.svg');
      background-size: 0.5em 0.5em;
    }
  }

  .row {
    margin-top: calc(var(--rem) * 30);
    flex-basis: 100%;
    display: flex;
  }

  .text {
    margin-top: calc(var(--rem) * 20);
    margin-bottom: calc(var(--rem) * -9);
    text-align: left;
  }
}
</style>
