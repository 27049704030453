<template>
  <div class="card">
    <div class="card-title" @click="toggleVisibility">
      <div class="title">{{ translations.shareholderInfo.title }}</div>
      <div :class="{arrow: true, closed: !isVisible}">
        <img src="~@/assets/arrow.svg" />
      </div>
    </div>

    <transition name="slide">

      <div class="hiding" v-show="isVisible">
        <div class="label">
          {{ translations.shareholderInfo.numberOfShareholders }}
        </div>

        <NumberInput :value="shareholderInfo.numberOfShareholders" @valueChanged="setShareholderInfo('numberOfShareholders', $event)"/>
      </div>

    </transition>
  </div>
</template>

<script>
import {translations} from "@/utils";
import NumberInput from "@/components/NumberInput";

export default {
  name: 'ShareholderInfo',
  components: { NumberInput },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    shareholderInfo: {
      required: true
    },
  },
  watch: {
    open(val) {
      this.isVisible = val;
    }
  },
  data() {
    return {
      translations: translations,
      isVisible: true,
    }
  },
  methods: {
    setShareholderInfo(field, value) {
      let shareholderInfo = this.shareholderInfo;
      shareholderInfo[field] = value;
      this.$emit('dataChanged', shareholderInfo);
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    }
  },
  mounted() {
    this.isVisible = this.open;
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .hiding {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .arrow {
    align-self: center;
    display: flex;

    img {
      height: calc(var(--rem) * 4);
      width: calc(var(--rem) * 8);
    }

    &.closed img {
      transform: rotate(180deg);
    }
  }

  .title {
    display: block;
    font-weight: 700;
  }

  .label {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 21);
    margin-bottom: calc(var(--rem) * 8);
    text-align: left;
  }

  .inline-label {
    text-align: left;
  }

  .row-spacing {
    margin: calc(var(--rem) * 15) 0;
  }
}
</style>
